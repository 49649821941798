import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getCategory,
    getCategorySuccess,
    getCategoryFailure,
    getCategoryIdle,
    setClearCategory
} from "./actions";

const status = handleActions(
    {
        [getCategorySuccess]: () => "SUCCESS",
        [getCategory]: () => "LOADING",
        [getCategoryFailure]: () => "FAILURE",
        [getCategoryIdle]: () => "IDLE",
    },
    'IDLE'
);

const shops = handleActions(
    {
        [getCategorySuccess]: (state, action) => action.payload.shops,
        [setClearCategory]: (state, action) => null
    },
    null
)

const sales = handleActions(
    {
        [getCategorySuccess]: (state, action) => action.payload.sales.data,
        [setClearCategory]: (state, action) => null
    },
    null
)

const category = combineReducers({
    status,
    shops,
    sales
});

export { category };
