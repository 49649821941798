import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import {
  getConfig,
  getConfigSuccess,
  getConfigFailure,
  getConfigIdle,
} from "./actions";

const status = handleActions(
  {
    [getConfigSuccess]: () => "SUCCESS",
    [getConfig]: () => "LOADING",
    [getConfigFailure]: () => "FAILURE",
    [getConfigIdle]: () => "IDLE",
  },
  "IDLE"
);

const data = handleActions(
  {
    [getConfigSuccess]: (state, action) => action.payload || {},
  },
  {}
);

const config = combineReducers({
  status,
  data,
});

export { config };
