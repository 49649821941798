import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";

import {
  setFilterTypes,
  setFilterCategory,
} from "../../../../modules/filter/actions";
import FilterCheckbox from "../filterCheckbox";
import { getShopsByFilter } from "../../../../modules/shopsByFilter/actions";

const ListFilter = (props) => {
  const dispatch = useDispatch();

  const statusShopsByFilter = useSelector(
    (state) => state.shopsByFilter.status
  );
  const currentCity = useSelector((state) => state.initData.city);
  let filterType = useSelector((state) => state.initData.storeTypes);
  let filterCategory = useSelector((state) => state.initData.categories);
  let categoriesById = useSelector((state) => state.initData.categoriesById);
  const categoriesSortById = useSelector(
    (state) => state.initData.categoriesSortById
  );
  let filterCategoryWithItems = useSelector(
    (state) => state.initData.categoriesWithItemsIds
  );

  const activesTypes = useSelector((state) => state.filter.activesTypes);
  const activesCategory = useSelector((state) => state.filter.activesCategory);

  const handleChangeTypes = (e) => {
    let checkedFilter = activesTypes;
    if (e.target.checked) {
      checkedFilter = [...checkedFilter, parseInt(e.target.value)];
    } else {
      const indexFilterItem = checkedFilter.indexOf(parseInt(e.target.value));
      if (indexFilterItem > -1) {
        checkedFilter.splice(indexFilterItem, 1);
      }
    }

    if (statusShopsByFilter == "IDLE") {
      dispatch(
        getShopsByFilter({
          currentCity,
          activesTypes: checkedFilter,
          activesCategory,
        })
      );
    }

    dispatch(setFilterTypes(checkedFilter));
  };

  const handleChangeCategory = (e, type) => {
    let checkedFilter = activesCategory;
    if (e.target.checked) {
      checkedFilter = [...checkedFilter, parseInt(e.target.value)];
    } else {
      const indexFilterItem = checkedFilter.indexOf(parseInt(e.target.value));
      if (indexFilterItem > -1) {
        checkedFilter.splice(indexFilterItem, 1);
      }
    }

    if (statusShopsByFilter == "IDLE") {
      dispatch(
        getShopsByFilter({
          currentCity,
          activesTypes,
          activesCategory: checkedFilter,
        })
      );
    }
    dispatch(setFilterCategory(checkedFilter));
  };

  let handleResetFilter = () => {
    if (statusShopsByFilter == "IDLE") {
      dispatch(
        getShopsByFilter({
          currentCity,
          activesTypes: [],
          activesCategory: [],
        })
      );
    }
    dispatch(setFilterCategory([]));
    dispatch(setFilterTypes([]));
  };

  return (
    <div className="filter _list">
      <div className="filter__category">
        <div className="filter__category-title">Тип магазина</div>
        <div className="filter__category-list">
          {filterType &&
            filterType.map((item, index) => {
              return (
                <FilterCheckbox
                  callback={handleChangeTypes}
                  key={item.id}
                  value={item.id}
                  title={item.attributes.Title}
                  name="type"
                  checked={
                    (activesTypes.indexOf(item.id) > -1 && true) || false
                  }
                />
              );
            })}
        </div>
      </div>
      <div className="filter__category">
        <div className="filter__category-title">Категории</div>
        <div className="filter__category-list">
          <FilterCheckbox
            callback={(e) => {
              let checkedFilter = [];
              if (e.target.checked) {
                filterCategory.map((item, index) => {
                  checkedFilter.push(item.id);
                });
              }
              if (statusShopsByFilter == "IDLE") {
                dispatch(
                  getShopsByFilter({
                    currentCity,
                    activesTypes,
                    activesCategory: checkedFilter,
                  })
                );
              }
              dispatch(setFilterCategory(checkedFilter));
            }}
            value={"all"}
            title={"Выбрать всё"}
            name="category"
          />
          {categoriesSortById &&
            categoriesSortById.map((id, index) => {
              let item = categoriesById[id];
              return (
                <div className="filter__list-item dp" key={id}>
                  <FilterCheckbox
                    callback={handleChangeCategory}
                    key={index}
                    value={id}
                    title={item.Title}
                    name="category"
                    checked={
                      (activesCategory.indexOf(parseInt(id)) >= 0 && true) ||
                      false
                    }
                  />
                </div>
              );
            })}
          {/* {categoriesById && filterCategoryWithItems && filterCategoryWithItems.map((id, index) => {

                        let item = categoriesById[id];

                        return (
                            <FilterCheckbox
                                callback={handleChangeCategory}
                                key={index}
                                value={id}
                                title={item.Title}
                                name="category"
                                checked={activesCategory.indexOf(id) >= 0 && true || false}
                            />
                        )
                    })} */}
        </div>
      </div>
      {window.innerWidth > 1023 && (
        <button
          style={{ marginBottom: "32px" }}
          onClick={handleResetFilter}
          type="button"
          className="button _default _gray"
        >
          Сбросить фильтр
        </button>
      )}
    </div>
  );
};

export default ListFilter;
