import { createSelector } from "reselect";
import { selectorShopsByFilter } from "../shopsByFilter/selector";
export const filterCategory = (state) => state.initData.categories;

const getCategoriesById = (state) => state.initData.categoriesById;

export const selectCategoriesById = createSelector(
  getCategoriesById,
  selectorShopsByFilter,
  (categoriesById, shopsByFilter) => {
    let shopsObj = {};
    let i = 0;
    if (shopsByFilter) {
      shopsByFilter.forEach((cat) => {
        const catId = Object.keys(cat)[0];
        if (catId && cat[catId].data) {
          cat[catId].data.forEach((shop) => {
            shopsObj[shop.id] = { ...shop.attributes };
            i++;
          });
        }
      });
    }
    let newCategoriesById = {};

    if (categoriesById) {
      Object.keys(categoriesById).forEach((catKey) => {
        newCategoriesById = {
          ...newCategoriesById,
          [catKey]: { ...categoriesById[catKey] },
        };
        if (categoriesById[catKey].Shops && categoriesById[catKey].Shops.data) {
          let shops = [];
          categoriesById[catKey].Shops.data.forEach((shop) => {
            if (shop.id && shopsObj[shop.id]) {
              shops = [
                ...shops,
                { id: shop.id, attributes: { ...shopsObj[shop.id] } },
              ];
            }
          });
          newCategoriesById[catKey] = {
            ...newCategoriesById[catKey],
            Shops: { data: [...shops] },
          };
        }
      });
    }
    return newCategoriesById;
  }
);
