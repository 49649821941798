import React, { useState } from "react";
import Modal from "../modal/modal";
import SearchForm from "../../components/searchForm";
import ListCities from "./components/listCities";

const Cities = (props) => {

    const { showCities, setShowCities } = props;

    const [valueSearch, setValueSearch] = useState('');

    return (
        <Modal 
            show={showCities} 
            toggle={setShowCities} 
            header={<SearchForm valueSearch={valueSearch} callback={(value) => {
                setValueSearch(value);
            }} />} 
            content={<ListCities valueSearch={valueSearch} />}
            dopClass={'_cities'}
        ></Modal>
    )
}

export default Cities;