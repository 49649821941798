import {createActions} from "redux-actions";

const {
    getCategory,
    getCategorySuccess,
    getCategoryFailure,
    getCategoryIdle,
    setClearCategory
} = createActions(
    "GET_CATEGORY",
    "GET_CATEGORY_SUCCESS",
    "GET_CATEGORY_FAILURE",
    "GET_CATEGORY_IDLE",
    "SET_CLEAR_CATEGORY"
);


export {
    getCategory,
    getCategorySuccess,
    getCategoryFailure,
    getCategoryIdle,
    setClearCategory
};
