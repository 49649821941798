import {createActions} from "redux-actions";

const {
    getShop,
    getShopSuccess,
    getShopFailure,
    getShopIdle,
    setClearShop
} = createActions(
    "GET_SHOP",
    "GET_SHOP_SUCCESS",
    "GET_SHOP_FAILURE",
    "GET_SHOP_IDLE",
    "SET_CLEAR_SHOP"
);


export {
    getShop,
    getShopSuccess,
    getShopFailure,
    getShopIdle,
    setClearShop
};
