import {createActions} from "redux-actions";

const {
    setFilterTypes,
    setFilterCategory,
    clearFilter
} = createActions(
    'SET_FILTER_TYPES',
    'SET_FILTER_CATEGORY',
    'CLEAR_FILTER'
);

export {
    setFilterTypes,
    setFilterCategory,
    clearFilter
};
