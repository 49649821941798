import "./assets/css/style.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Cities from "./layout/cities";
import Filter from "./layout/filter";
import Search from "./layout/search";

import Shop from "./pages/shop";
import Sales from "./pages/sales";
import Category from "./pages/category";
import Home from "./pages/home";
import Pokupki from "./pages/pokupki";
import QR from "./pages/qr";
import Chat from "./pages/chat";
import Profile from "./pages/profile";
import { getInitData } from "./modules/initData/actions";
import Sale from "./pages/sale";
import { getShopsByFilterIdle } from "./modules/shopsByFilter/actions";
import { Theme } from "./context";
import { getConfig } from "./modules/config/actions";
import { selectConfigStatus } from "./modules/config/selector";

function App() {
  const dispatch = useDispatch();

  const statusShopsByFilter = useSelector(
    (state) => state.shopsByFilter.status
  );
  const stateSearchValue = useSelector((state) => state.search.searchValue);

  useEffect(() => {
    if (configStatus === "SUCCESS" && statusShopsByFilter == "SUCCESS") {
      dispatch(getShopsByFilterIdle());
    }
  }, [statusShopsByFilter]);

  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [globalSearchValue, setGlobalSearchValue] = useState("");

  const [showCities, setShowCities] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [valueSearchHome, setValueSearchHome] = useState("");

  const [showSearch, setShowSearch] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const configStatus = useSelector(selectConfigStatus);
  useEffect(() => {
    dispatch(getConfig());
  }, []);
  // useEffect(() => {
  //   if(globalSearchValue && globalSearchValue.length > 0){
  //     setShowSearch(true);
  //   }
  // }, [globalSearchValue])

  useEffect(() => {
    if (configStatus === "SUCCESS") {
      dispatch(getInitData());
    }
  }, [configStatus]);

  useEffect(() => {
    if (globalSearchValue) {
      setShowSearch(true);
      inputSearchRef.current.focus();
    }
  }, [globalSearchValue]);

  return (
    <Theme.Provider
      value={{
        globalSearchValue,
        setGlobalSearchValue,
      }}
    >
      {configStatus === "SUCCESS" && (
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  showCities={showCities}
                  setShowCities={setShowCities}
                  showFilter={showFilter}
                  setShowFilter={setShowFilter}
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  valueSearch={valueSearch}
                  setValueSearch={setValueSearch}
                  inputSearchRef={inputSearchRef}
                  valueSearchHome={valueSearchHome}
                  setValueSearchHome={setValueSearchHome}
                  globalSearchValue={globalSearchValue}
                  setGlobalSearchValue={setGlobalSearchValue}
                />
              }
            />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/sales/:id" element={<Sales />} />
            <Route path="/pokupki" element={<Pokupki />} />
            <Route path="/qr" element={<QR />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/shop/:id"
              element={
                <Shop
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  valueSearch={valueSearch}
                  setValueSearch={setValueSearch}
                />
              }
            />
          </Routes>
          <Cities showCities={showCities} setShowCities={setShowCities} />
          {window.innerWidth < 1023 && (
            <Filter showFilter={showFilter} setShowFilter={setShowFilter} />
          )}
          <Search
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            valueSearch={valueSearch}
            setValueSearch={setValueSearch}
            setInputSearchRef={setInputSearchRef}
            valueSearchHome={valueSearchHome}
            setValueSearchHome={setValueSearchHome}
            globalSearchValue={globalSearchValue}
            setGlobalSearchValue={setGlobalSearchValue}
          />

          <Sale />
        </HashRouter>
      )}
    </Theme.Provider>
  );
}

export default App;
