import { fork } from "redux-saga/effects";
import { getInitDataWatcher } from "./modules/initData/saga";
import { getSearchWatcher } from "./modules/search/saga";
import { getSaleWatcher } from "./modules/sale/saga";
import { getSalesWatcher } from "./modules/sales/saga";
import { getShopsByFilterWatcher } from "./modules/shopsByFilter/saga";
import { getShopWatcher } from "./modules/shop/saga";
import { getCategoryWatcher } from "./modules/category/saga";
import { getConfigWatcher } from "./modules/config/saga";

export default function* rootSaga() {
  yield fork(getInitDataWatcher);
  yield fork(getSearchWatcher);
  yield fork(getSaleWatcher);
  yield fork(getSalesWatcher);
  yield fork(getShopsByFilterWatcher);
  yield fork(getShopWatcher);
  yield fork(getCategoryWatcher);
  yield fork(getConfigWatcher);
}
