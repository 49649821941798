import { createSelector } from "reselect";
import {
  getUrlImage,
  catalogType,
  CATALOG_TYPES,
} from "../../constants/config";

const stateShopData = (state) => state.shop.data;

export const selectorShop = createSelector(stateShopData, (data) => {
  let shop = {};
  if (data) {
    let { attributes, id } = data;

    let {
      Title,
      Preview,
      Logo,
      Description,
      Sale_card,
      Sale,
      Alpha_card,
      Sale_alpha,
      Retail_store,
      Web_store,
      App_store,
      Categories,
      Hide_web_store_utm,
      Hide_retail_store_utm,
      Hide_app_store_utm,
      Web_store_title,
      Retail_store_title,
      App_store_title,
      AM_store,
      AM_store_title,
      Hide_am_store_utm,
      AO_store,
      AO_store_title,
      Hide_ao_store_utm,
      AM_Retail,
      AO_Retail,
      App_Retail,
    } = attributes;

    let percentSale = null;
    let percentSymbol = null;
    let titleSale = "";
    let organizatorSale = "";
    let promocodeSale = "";
    let infoSale = "";
    let idSale = null;
    let saleWebStore = null;
    let saleRetailStore = null;
    let saleTitleOnGrayGroup = null;
    let saleSubTitleOnGrayGroup = null;
    let saleTitleInner = null;
    let related = [];
    let Sale_Web_store_title = null;
    let Sale_Retail_store_title = null;
    if (Categories && Categories.data && Categories.data.length > 0) {
      Categories.data.map((category) => {
        if (
          category.attributes.Shops &&
          category.attributes.Shops.data &&
          category.attributes.Shops.data.length > 0
        ) {
          category.attributes.Shops.data.map((shop) => {
            related = [...related, shop];
          });
        }
      });
      if (related.length > 0) {
        related = related.filter((item) => item.id !== id);
        if (related.length > 8) {
          related = related.slice(0, 8);
        }
      }
    }
    if (Sale_card && Sale_card.Percent) {
      percentSale = Sale_card.Percent;
      percentSymbol = Sale_card.Percent_symbol;
      titleSale = Sale_card.Title;
      organizatorSale = Sale_card.Organizator;
      promocodeSale = Sale_card.Promocode;
      infoSale = Sale_card.Info;
      saleTitleOnGrayGroup = Sale_card.Title_on_gray_group;
      saleSubTitleOnGrayGroup = Sale_card.SubTitle_on_gray_group;
      if (Sale_card.Web_store) {
        saleWebStore = Sale_card.Web_store;
      }
      if (Sale_card.Retail_store) {
        saleRetailStore = Sale_card.Retail_store;
      }
      if (Sale_card.Title_inner) {
        saleTitleInner = Sale_card.Title_inner;
      }
      if (Sale_card.Web_store_title) {
        Sale_Web_store_title = Sale_card.Web_store_title;
      }
      if (Sale_card.Retail_store_title) {
        Sale_Retail_store_title = Sale_card.Retail_store_title;
      }
    } else {
      if (Sale && Sale.data) {
        idSale = Sale.data.id;
        if (
          Sale.data.attributes &&
          Sale.data.attributes.Sale_card &&
          Sale.data.attributes.Sale_card.publishedAt
        ) {
          if (Sale.data.attributes.Sale_card.Title_on_gray_group) {
            saleTitleOnGrayGroup =
              Sale.data.attributes.Sale_card.Title_on_gray_group;
          }
          if (Sale.data.attributes.Sale_card.SubTitle_on_gray_group) {
            saleSubTitleOnGrayGroup =
              Sale.data.attributes.Sale_card.SubTitle_on_gray_group;
          }
          if (Sale.data.attributes.Sale_card.Percent) {
            percentSale = Sale.data.attributes.Sale_card.Percent;
          }
          if (Sale.data.attributes.Sale_card.Percent_symbol) {
            percentSymbol = Sale.data.attributes.Sale_card.Percent_symbol;
          }
          if (Sale.data.attributes.Sale_card.Web_store) {
            saleWebStore = Sale.data.attributes.Sale_card.Web_store;
          }
          if (Sale.data.attributes.Sale_card.Retail_store) {
            saleRetailStore = Sale.data.attributes.Sale_card.Retail_store;
          }
          if (Sale.data.attributes.Sale_card.Title_inner) {
            saleTitleInner = Sale.data.attributes.Sale_card.Title_inner;
          }
          if (Sale.data.attributes.Sale_card.Web_store_title) {
            Sale_Web_store_title =
              Sale.data.attributes.Sale_card.Web_store_title;
          }
          if (Sale.data.attributes.Sale_card.Retail_store_title) {
            Sale_Retail_store_title =
              Sale.data.attributes.Sale_card.Retail_store_title;
          }
        }
      }
    }

    let percentSaleAlpha = null;
    let previewSaleAlpha = null;
    let titleSaleAlpha = "";
    let descriptionSaleAlpha = "";
    let linkSaleAlpha = null;
    let saleTitleAlphaOnGrayGroup = "";
    let saleSubTitleAlphaOnGrayGroup = "";

    if (Alpha_card) {
      percentSaleAlpha = Alpha_card.Percent;
      if (Alpha_card.Preview) {
        previewSaleAlpha = getUrlImage(Alpha_card.Preview);
      }
      if (Alpha_card.Title) {
        titleSaleAlpha = Alpha_card.Title;
      }
      if (Alpha_card.Description) {
        descriptionSaleAlpha = Alpha_card.Description;
      }
      if (Alpha_card.Link) {
        linkSaleAlpha = Alpha_card.Link;
      }
      saleTitleAlphaOnGrayGroup = Alpha_card.Title_on_gray_group;
      saleSubTitleAlphaOnGrayGroup = Alpha_card.SubTitle_on_gray_group;
    } else {
      if (Sale_alpha && Sale_alpha.data) {
        percentSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Percent;
        if (Sale_alpha.data.attributes.Alpha_card.Preview) {
          previewSaleAlpha = getUrlImage(
            Sale_alpha.data.attributes.Alpha_card.Preview
          );
        }
        if (Sale_alpha.data.attributes.Alpha_card.Title) {
          titleSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Title;
        }
        if (Sale_alpha.data.attributes.Alpha_card.Description) {
          descriptionSaleAlpha =
            Sale_alpha.data.attributes.Alpha_card.Description;
        }
        if (Sale_alpha.data.attributes.Alpha_card.Link) {
          linkSaleAlpha = Sale_alpha.data.attributes.Alpha_card.Link;
        }
        saleTitleAlphaOnGrayGroup =
          Sale_alpha.data.attributes.Alpha_card.Title_on_gray_group;
        saleSubTitleAlphaOnGrayGroup =
          Sale_alpha.data.attributes.Alpha_card.SubTitle_on_gray_group;
      }
    }

    const im_title = {
      [CATALOG_TYPES.CATALOG]: Web_store_title,
      [CATALOG_TYPES.APP]: App_store_title || Web_store_title,
      [CATALOG_TYPES.AM]: AM_store_title || Web_store_title,
      [CATALOG_TYPES.AO]: AO_store_title || Web_store_title,
    }[catalogType];
    const im_url = {
      [CATALOG_TYPES.CATALOG]: Web_store,
      [CATALOG_TYPES.APP]: App_store || Web_store,
      [CATALOG_TYPES.AM]: AM_store || Web_store,
      [CATALOG_TYPES.AO]: AO_store || Web_store,
    }[catalogType];
    const im_utm_hide = {
      [CATALOG_TYPES.CATALOG]: Hide_web_store_utm,
      [CATALOG_TYPES.APP]: Hide_app_store_utm || Hide_web_store_utm,
      [CATALOG_TYPES.AM]: Hide_am_store_utm || Hide_web_store_utm,
      [CATALOG_TYPES.AO]: Hide_ao_store_utm || Hide_web_store_utm,
    }[catalogType];
    const retail_url = {
      [CATALOG_TYPES.CATALOG]: Retail_store,
      [CATALOG_TYPES.APP]: App_Retail || Retail_store,
      [CATALOG_TYPES.AM]: AM_Retail || Retail_store,
      [CATALOG_TYPES.AO]: AO_Retail || Retail_store,
    }[catalogType];

    shop = {
      title: Title,
      preview: getUrlImage(Preview),
      logo: getUrlImage(Logo),
      description: Description.replaceAll("\n", "&nbsp; \n\n"),
      percentSale: percentSale,
      percentSymbol: percentSymbol,
      idSale: idSale,
      related,
      titleSale: titleSale,
      organizatorSale: organizatorSale,
      promocodeSale: promocodeSale,
      infoSale: infoSale,
      percentSaleAlpha: percentSaleAlpha,
      previewSaleAlpha: previewSaleAlpha,
      titleSaleAlpha: titleSaleAlpha,
      descriptionSaleAlpha: descriptionSaleAlpha,
      linkSaleAlpha: linkSaleAlpha,
      Retail_store_title,
      Hide_retail_store_utm,
      Retail_store: retail_url,
      saleWebStore: saleWebStore,
      saleRetailStore: saleRetailStore,
      saleTitleOnGrayGroup: saleTitleOnGrayGroup,
      saleSubTitleOnGrayGroup: saleSubTitleOnGrayGroup,
      saleTitleInner: saleTitleInner,
      saleTitleAlphaOnGrayGroup: saleTitleAlphaOnGrayGroup,
      saleSubTitleAlphaOnGrayGroup: saleSubTitleAlphaOnGrayGroup,
      Web_store: im_url,
      Web_store_title: im_title,
      Hide_web_store_utm: im_utm_hide,
      Sale_Web_store_title,
      Sale_Retail_store_title,
    };
  }

  return shop;
});
