import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getSales,
    getSalesSuccess,
    getSalesFailure,
    getSalesIdle,
} from "./actions";

const status = handleActions(
    {
        [getSalesSuccess]: () => "SUCCESS",
        [getSales]: () => "LOADING",
        [getSalesFailure]: () => "FAILURE",
        [getSalesIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getSalesSuccess]: (state, action) => action.payload.data
    },
    []
)

const sales = combineReducers({
    status,
    data
});

export {sales};
