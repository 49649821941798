import { call, put, takeEvery, all } from "redux-saga/effects";

import { getConfigRequest } from "./api";

import {
  getConfig,
  getConfigSuccess,
  getConfigFailure,
  getConfigIdle,
} from "./actions";

export function* getConfigSaga() {
  try {
    
    const config = yield call(getConfigRequest);
    yield put(getConfigSuccess(config));
  } catch (error) {
    console.log(error);
    yield put(getConfigFailure(error));
  }
}

export function* getConfigWatcher() {
  yield takeEvery(getConfig.toString(), getConfigSaga);
}
