import { createSelector } from 'reselect';
import { getUrlImage } from '../../constants/config';

const stateSearchShops = state => state.search.shops;

export const selectorSearchShops = createSelector(stateSearchShops, data => {

    let shops = [];


    if(data && data.length > 0){
        data.map(item => {

            const { id, attributes } = item;

            const { Title, Preview, Logo, Categories, Preview_list, Subtitle, Sale, Sale_card, Show_percent_on_preview } = attributes;

            let categoriesTitle = '';

            if(Categories && Categories.data && Categories.data.length > 0){
                categoriesTitle = Categories.data[0].attributes.Title;
            }

            let Percent = null;

            let Percent_symbol = null;
            
            if(Sale_card){
                if(Sale_card.Percent){
                    Percent = Sale_card.Percent;
                }
                if(Sale_card.Percent_symbol){
                    Percent_symbol = Sale_card.Percent_symbol;
                }
            } else {
                if(Sale && Sale.data && Sale.data.attributes && Sale.data.attributes.Sale_card){
                    if(Sale.data.attributes.Sale_card.Percent){
                        Percent = Sale.data.attributes.Sale_card.Percent;
                    }
                    if(Sale.data.attributes.Sale_card.Percent_symbol){
                        Percent_symbol = Sale.data.attributes.Sale_card.Percent_symbol;
                    }
                }
            }

            shops.push({
                id: id,
                title: Title,
                previewUrl: Preview_list && getUrlImage(Preview_list) || Preview && getUrlImage(Preview),
                logoUrl: Logo && getUrlImage(Logo),
                categoryTitle: categoriesTitle,
                subTitle: Subtitle,
                sale: ( Show_percent_on_preview || Show_percent_on_preview == null ) && Percent && '-'+Percent+( Percent_symbol && Percent_symbol || '%') || null,
                attributes: attributes
            })
        })
    }

    return shops;
});