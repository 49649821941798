import React, { useRef, useState, useEffect, useContext } from "react";

import { useSelector, useDispatch } from 'react-redux';

import './style.css';
import { setSearchValue } from "../../modules/search/actions";
import { Theme } from '../../context';

const SearchForm = (props) => {

    const theme = useContext(Theme);

    const dispatch = useDispatch();
    const stateSearchValue = useSelector(state => state.search.searchValue);

    const { callback, valueSearch, setValueSearch, setInputOutRef, callbackReset } = props;

    const inputRef = useRef(null);

    // const [value, setValue] = useState('');

    // useEffect(() => {
    //     // if(valueSearch){
    //         setValue(valueSearch);
    //     // }
    // }, [valueSearch]);

    useEffect(() => {
        if(inputRef && setInputOutRef){
            setInputOutRef(inputRef);
        }
    }, [inputRef])

    // useEffect(() => {

    //     dispatch(setSearchValue(value));

    // }, [value])

    const handleChange = (e) => {

        theme.setGlobalSearchValue(e.target.value);

    }

    const handleReset = (e) => {
        e.preventDefault();

        theme.setGlobalSearchValue('');

        if(callbackReset){
            callbackReset();
        }

    }

    return (
        <div className='search'>
            <input ref={inputRef} type="text" className='search__input' value={theme.globalSearchValue} onChange={handleChange} placeholder='Поиск'/>
            {stateSearchValue !== '' && (
                <a href="#" className='search__reset' onClick={e => {
                    handleReset(e);
                }}>
                    <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-reset-search.svg" />
                </a>
            )}
        </div>
    )

}

export default SearchForm;