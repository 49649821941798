import React, { useRef, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { setCity } from "../../../../modules/initData/actions";
import { getShopsByFilter } from "../../../../modules/shopsByFilter/actions";

import './style.css'

const ItemCity = (props) => {

    const dispatch = useDispatch();

    const { id, attributes, checked } = props;

    const { Title } = attributes;

    const inputRef = useRef(null);

    const statusShopsByFilter = useSelector(state => state.shopsByFilter.status);
    const activesTypes = useSelector(state => state.filter.activesTypes);
    const activesCategory = useSelector(state => state.filter.activesCategory);

    const handleCity = (e) => {
        if(dispatch){
            dispatch(setCity({
                Title,
                id
            }));
            if(statusShopsByFilter == 'IDLE'){
                dispatch(getShopsByFilter({
                    currentCity: {
                        Title,
                        id
                    }, 
                    activesTypes, 
                    activesCategory
                }))
            }
        }   
    }

    useEffect(() => {
        if(checked){
            inputRef.current.checked = checked;
        }
    }, [checked])

    return (
        <label className='checkbox _city'>
            <input ref={inputRef} onChange={handleCity} className='checkbox__input' type="radio" value={id} name={'city'} />
            <span className='checkbox__wrap'>
                <span className='checkbox__title'>{Title}</span>
            </span>
        </label>
    )    
}

export default ItemCity;