import React, { useEffect, useState, useCallback, useRef } from "react";

import "./style.css";

import { useSelector, useDispatch } from "react-redux";
import { getInitData } from "../../modules/initData/actions";
import { Link } from "react-router-dom";

import Filter from "../../layout/filter";

import SearchForm from "../../components/searchForm";
import PostSale from "../../components/postSale";
import PostShop from "../../components/postShop";

import CategoryCheckbox from "./components/categoryCheckbox";

import { setFilterCategory } from "../../modules/filter/actions";

import {
  getUrlImage,
  targetMetrick,
  isCatalog,
  shuffleArrayExceptFirstThree,
} from "../../constants/config";
import { selectorShopsByFilter } from "../../modules/shopsByFilter/selector";
import { getShopsByFilter } from "../../modules/shopsByFilter/actions";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import { selectCategoriesById } from "../../modules/initData/selector";

const Home = (props) => {
  const {
    showFilter,
    setShowFilter,
    showSearch,
    setShowSearch,
    inputSearchRef,
    setValueSearch,
    valueSearchHome,
    setValueSearchHome,
  } = props;

  const [salesSwiper, setSalesSwiper] = useState(null);

  const slider = useRef(null);

  const dispatch = useDispatch();

  const currentCity = useSelector((state) => state.initData.city);

  const categoriesById = useSelector(selectCategoriesById);

  const categoriesSortById = useSelector(
    (state) => state.initData.categoriesSortById
  );

  const activesCategory = useSelector((state) => state.filter.activesCategory);
  const activesTypes = useSelector((state) => state.filter.activesTypes);

  const topSales = useSelector((state) => state.initData.topSales);
  const topAlpha = useSelector((state) => state.initData.topAlpha);

  const allSalesCount = useSelector((state) => state.initData.allSalesCount);

  const shopsByFilter = useSelector(selectorShopsByFilter);

  const statusShopsByFilter = useSelector(
    (state) => state.shopsByFilter.status
  );
  useEffect(() => {
    if (dispatch) {
      dispatch(
        getInitData({
          currentCity,
          activesTypes,
          activesCategory,
          categoriesById,
          main: true,
        })
      );
    }
  }, []);

  const handleShowFilter = (e) => {
    // e.preventDefault();

    setShowFilter(!showFilter);

    targetMetrick("Открытие/закрытие фильтра");
  };

  const handleChangeFilter = (e, type) => {
    let checkedFilter = activesCategory;

    targetMetrick();

    if (e.target.checked) {
      checkedFilter = [...checkedFilter, parseInt(e.target.value)];
    } else {
      const indexFilterItem = checkedFilter.indexOf(parseInt(e.target.value));
      if (indexFilterItem > -1) {
        checkedFilter.splice(indexFilterItem, 1);
      }
    }

    if (statusShopsByFilter == "IDLE") {
      dispatch(
        getShopsByFilter({
          currentCity,
          activesTypes,
          activesCategory: checkedFilter,
          categoriesById,
        })
      );
    }
    dispatch(setFilterCategory(checkedFilter));
  };

  return (
    <div className="page _home">
      <div className="page__filter _desktop">
        <div className={`filter-list _desktop ${showFilter ? "_show" : ""}`}>
          <div className="page__title">Магазины</div>
          <button
            className="button filter-list__close"
            onClick={(e) => setShowFilter(false)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.34302 17.6568L11.9999 12M11.9999 12L17.6567 6.34313M11.9999 12L17.6567 17.6568M11.9999 12L6.34302 6.34313"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          <div className="filter-list__title">Фильтр:</div>
          <div className="filter-list__wrap">
            <Filter
              type="inline"
              showFilter={showFilter}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>
        <div className="filter _category">
          <button className="filter__toggle" onClick={handleShowFilter}>
            <img
              alt="toggle"
              src="https://cdn.podeli.ru/web-catalog/assets/img/icon-filter-toggle.svg"
            />
          </button>
          <div className="filter__list">
            {categoriesSortById &&
              categoriesSortById.map((id, index) => {
                let item = categoriesById[id];
                if (id == 13 || id == 14) {
                  return true;
                }

                return (
                  <div className="filter__list-item" key={id}>
                    <CategoryCheckbox
                      callback={handleChangeFilter}
                      target={"Клик.Фильтр." + item.Title}
                      title={item.Title}
                      icon={item && item.Icon && getUrlImage(item.Icon)}
                      value={id}
                      name={"category"}
                      checked={
                        (activesCategory.indexOf(parseInt(id)) >= 0 && true) ||
                        false
                      }
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="page__wrap">
        <div className="page__hero">
          <div className="page__header">
            <div className="page__title">Магазины</div>
            <div className="page__user"></div>
          </div>
          <div className="page__search">
            <SearchForm />
          </div>
        </div>
        {window.innerWidth < 767 &&
          window.location.href.indexOf("flow=personal_offer") >= 0 && (
            <div className="special">
              <div className="special__title">Специально для вас</div>
              <div className="special__preview">
                <img src="https://podeli.storage.yandexcloud.net/Group_1948754677_087ba9a27a.png" />
                <div className="special__rating">
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                      fill="#FFBD2E"
                    />
                  </svg>
                  <span>4.8</span>
                </div>
              </div>
              <div className="special__content">
                <div className="row">
                  <div className="special__name">Lanvin Eclat D'Arpege</div>
                  <div className="special__price">789 ₽ x 4</div>
                </div>
                <div className="row">
                  <div className="special__desc">Парфюмерная вода 50 мл.</div>
                  <div className="special__total">3156 ₽</div>
                </div>
              </div>
              <a
                href="https://shop.podeli.ru/product_offer"
                class="button _default"
              >
                Купить
              </a>
            </div>
          )}
        <div className="page__category _sales">
          <div className="category _default _sale">
            <div className="category__header">
              <div className="category__title">
                <Link to={`/sales`}>Акции</Link>
              </div>
              {allSalesCount && (
                <Link to={`/sales`} className="category__link carousel-control">
                  Все {allSalesCount}{" "}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="#7F7E82" />
                    <path
                      d="M11.4102 6.92319L14.9998 9.99999L11.4102 13.0768"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.7432 9.99997H5"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              )}
            </div>
            <div ref={slider} className="category__slider _shadow-before">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={24}
                navigation={true}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                  setSalesSwiper(swiper);
                }}
                onSlideChange={(swiper) => {
                  if (swiper.activeIndex > 0) {
                    slider.current.classList.add("_shadow-after");
                  } else {
                    slider.current.classList.remove("_shadow-after");
                  }
                  if (swiper.isEnd) {
                    slider.current.classList.remove("_shadow-before");
                  } else {
                    slider.current.classList.add("_shadow-before");
                  }

                  // if(){

                  // }
                }}
              >
                {topAlpha &&
                  topAlpha.length > 0 &&
                  topAlpha.map((item, index) => {
                    const { attributes } = item;

                    const { Alpha_card } = attributes;

                    const {
                      Link,
                      Title_on_card,
                      Preview,
                      Description_on_card,
                    } = Alpha_card;

                    return (
                      <SwiperSlide
                        className="category__list-item"
                        key={item.id}
                      >
                        <PostSale
                          type={"top"}
                          link={Link}
                          preview={getUrlImage(Preview)}
                          title={Title_on_card}
                          description={Description_on_card}
                          attributes={attributes}
                        />
                      </SwiperSlide>
                    );
                  })}
                {topSales &&
                  topSales.length > 0 &&
                  topSales.map((item, index) => {
                    const { id, attributes } = item;

                    const { Sale_card, YandexTarget, Show_percent_on_home } =
                      attributes;
                    if (!Sale_card) {
                      return;
                    }
                    const {
                      Percent,
                      Preview,
                      Logo,
                      Percent_symbol,
                      Percent_Symbol_Before,
                    } = Sale_card;

                    if (!Preview || (Preview && !Preview.data)) {
                      return;
                    }

                    return (
                      <SwiperSlide
                        className="category__list-item"
                        key={item.id}
                      >
                        <PostSale
                          id={id}
                          preview={getUrlImage(Preview)}
                          logo={getUrlImage(Logo)}
                          price={
                            (Show_percent_on_home &&
                              Percent &&
                              ((Percent_Symbol_Before &&
                                Percent_Symbol_Before + " ") ||
                                "-") +
                                Percent +
                                ((Percent_symbol && Percent_symbol) || "%")) ||
                            null
                          }
                          target={YandexTarget}
                          // attributes={attributes}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <button
                className="category__slider-prev"
                onClick={() => salesSwiper.slidePrev()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 17.3594L9.6652 13.2432C9.15327 12.7571 9.15327 11.9616 9.6652 11.4755L14 7.35938"
                    stroke="#A7ABAC"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button
                className="category__slider-next"
                onClick={() => salesSwiper.slideNext()}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 17.3594L14.3348 13.2432C14.8467 12.7571 14.8467 11.9616 14.3348 11.4755L10 7.35938"
                    stroke="#A7ABAC"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="category__list">
              {topAlpha &&
                topAlpha.length > 0 &&
                topAlpha.map((item, index) => {
                  const { attributes } = item;

                  const { Alpha_card } = attributes;

                  const { Link, Title_on_card, Preview, Description_on_card } =
                    Alpha_card;

                  return (
                    <div className="category__list-item" key={item.id}>
                      <PostSale
                        type={"top"}
                        link={Link}
                        preview={getUrlImage(Preview)}
                        title={Title_on_card}
                        description={Description_on_card}
                        attributes={attributes}
                      />
                    </div>
                  );
                })}
              {topSales &&
                topSales.length > 0 &&
                topSales.map((item, index) => {
                  const { id, attributes } = item;

                  const { Sale_card, YandexTarget, Show_percent_on_home } =
                    attributes;
                  if (!Sale_card) {
                    return;
                  }
                  const {
                    Percent,
                    Preview,
                    Logo,
                    Percent_symbol,
                    Percent_Symbol_Before,
                  } = Sale_card;

                  if (!Preview || (Preview && !Preview.data)) {
                    return;
                  }

                  return (
                    <div className="category__list-item" key={item.id}>
                      <PostSale
                        id={id}
                        preview={getUrlImage(Preview)}
                        logo={getUrlImage(Logo)}
                        price={
                          (Show_percent_on_home &&
                            Percent &&
                            ((Percent_Symbol_Before &&
                              Percent_Symbol_Before + " ") ||
                              "-") +
                              Percent +
                              ((Percent_symbol && Percent_symbol) || "%")) ||
                          null
                        }
                        target={YandexTarget}
                        // attributes={attributes}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="page__filter _mobile">
          <div className={`filter-list _desktop ${showFilter ? "_show" : ""}`}>
            <button
              className="button filter-list__close"
              onClick={(e) => setShowFilter(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.34302 17.6568L11.9999 12M11.9999 12L17.6567 6.34313M11.9999 12L17.6567 17.6568M11.9999 12L6.34302 6.34313"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <div className="filter-list__title">Фильтр:</div>
            <div className="filter-list__wrap">
              <Filter
                type="inline"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            </div>
          </div>
          <div className="filter _category">
            <button className="filter__toggle" onClick={handleShowFilter}>
              <img
                alt="toggle"
                src="https://cdn.podeli.ru/web-catalog/assets/img/icon-filter-toggle.svg"
              />
            </button>
            <div className="filter__list">
              {categoriesSortById &&
                categoriesSortById.map((id, index) => {
                  let item = categoriesById[id];

                  if (id == 13 || id == 14) {
                    return true;
                  }

                  return (
                    <div className="filter__list-item" key={id}>
                      <CategoryCheckbox
                        callback={handleChangeFilter}
                        target={"Клик.Фильтр." + item.Title}
                        title={item.Title}
                        icon={item && item.Icon && getUrlImage(item.Icon)}
                        value={id}
                        name={"category"}
                        checked={
                          (activesCategory.indexOf(parseInt(id)) >= 0 &&
                            true) ||
                          false
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="page__full-search">
          <SearchForm
            valueSearch={valueSearchHome}
            callback={(value) => {
              if (value) {
                setValueSearchHome(value);
                setValueSearch(value);
                if (value.length > 0) {
                  if (window.innerWidth < 1023) {
                    setShowSearch(!showSearch);
                    inputSearchRef.current.focus();
                  }
                }
              }
            }}
          />
        </div>

        {categoriesById &&
          shopsByFilter &&
          Object.keys(shopsByFilter).length > 0 &&
          shuffleArrayExceptFirstThree(Object.values(shopsByFilter)).map(
            (item) => {
              let categoryId = Object.keys(item)[0];

              let data = [];

              let meta = null;

              if (Object.values(item)[0] && Object.values(item)[0].data) {
                data = Object.values(item)[0].data;
              }

              let sortData = [...categoriesById[categoryId].Shops.data];

              if (Object.values(item)[0] && Object.values(item)[0].meta) {
                meta = Object.values(item)[0].meta;
              }

              let total = categoriesById[categoryId].Shops.data.length;

              if (data && !data.length) {
                return;
              }

              return (
                <div className="page__category" key={categoryId}>
                  <div className="category _default _partners">
                    <div className="category__header">
                      <div className="category__title">
                        <Link
                          to={`/category/${categoryId}`}
                          onClick={(e) => {
                            targetMetrick(
                              categoriesById[categoryId] &&
                                categoriesById[categoryId].Title +
                                  ".Показать все"
                            );
                          }}
                        >
                          {categoriesById[categoryId] &&
                            categoriesById[categoryId].Title}
                        </Link>
                      </div>
                      {data && data.length > 0 && (
                        <Link
                          to={`/category/${categoryId}`}
                          onClick={(e) => {
                            targetMetrick(
                              categoriesById[categoryId] &&
                                categoriesById[categoryId].Title +
                                  ".Показать все"
                            );
                          }}
                          className="category__link carousel-control"
                        >
                          Все {total}{" "}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="20"
                              height="20"
                              rx="10"
                              fill="#7F7E82"
                            />
                            <path
                              d="M11.4102 6.92319L14.9998 9.99999L11.4102 13.0768"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14.7432 9.99997H5"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                      )}
                    </div>
                    {(sortData && sortData.length > 0 && (
                      <div className="category__list">
                        {sortData.map((item, index) => {
                          if (
                            (activesCategory.length !== 1 &&
                              !isCatalog &&
                              categoriesById[categoryId].Count_posts_homepage &&
                              index >=
                                categoriesById[categoryId]
                                  .Count_posts_homepage) ||
                            (activesCategory.length !== 1 &&
                              isCatalog &&
                              categoriesById[categoryId].Shops.data.length >=
                                8 &&
                              index > 7) ||
                            (activesCategory.length !== 1 &&
                              isCatalog &&
                              categoriesById[categoryId].Shops.data.length <
                                8 &&
                              index > 3)
                          ) {
                            return;
                          }

                          const { id, attributes } = item;

                          if (activesTypes.length > 0) {
                            if (activesTypes.indexOf(2) >= 0) {
                              if (
                                !attributes.Retail_store ||
                                !attributes.Retail_store.length
                              ) {
                                return;
                              }
                            }
                            if (activesTypes.indexOf(1) >= 0) {
                              if (
                                !attributes.Web_store ||
                                !attributes.Web_store.length
                              ) {
                                return;
                              }
                            }
                          }

                          const {
                            Title,
                            Preview,
                            Logo,
                            Sale,
                            Sale_card,
                            Preview_list,
                            Subtitle,
                            Show_percent_on_preview,
                            IsExternal,
                            Web_store
                          } = attributes;

                          let Percent = null;

                          let Percent_symbol = null;

                          let Percent_Symbol_Before = "-";

                          if (Sale_card) {
                            if (Sale_card.Percent) {
                              Percent = Sale_card.Percent;
                            }
                            if (Sale_card.Percent_symbol) {
                              Percent_symbol = Sale_card.Percent_symbol;
                            }
                            if (Sale_card.Percent_Symbol_Before) {
                              Percent_Symbol_Before =
                                Sale_card.Percent_Symbol_Before;
                            }
                          } else {
                            if (
                              Sale &&
                              Sale.data &&
                              Sale.data.attributes &&
                              Sale.data.attributes.Sale_card
                            ) {
                              if (Sale.data.attributes.Sale_card.Percent) {
                                Percent =
                                  Sale.data.attributes.Sale_card.Percent;
                              }
                              if (
                                Sale.data.attributes.Sale_card.Percent_symbol
                              ) {
                                Percent_symbol =
                                  Sale.data.attributes.Sale_card.Percent_symbol;
                              }
                            }
                          }

                          let linkDefault = `/shop/${item.id}`;

                          if(IsExternal){
                            linkDefault = Web_store;
                          }

                          return (
                            <div className="category__list-item" key={item.id}>
                              <PostShop
                                link={linkDefault}
                                preview={
                                  (Preview_list && getUrlImage(Preview_list)) ||
                                  (Preview && getUrlImage(Preview)) ||
                                  ""
                                }
                                isExternal={IsExternal}
                                logo={(Logo && getUrlImage(Logo)) || ""}
                                sale={
                                  ((Show_percent_on_preview ||
                                    Show_percent_on_preview == null) &&
                                    Percent &&
                                    Percent_Symbol_Before +
                                      Percent +
                                      ((Percent_symbol && Percent_symbol) ||
                                        "%")) ||
                                  null
                                }
                                title={Title}
                                category={categoriesById[categoryId].Title}
                                subTitle={Subtitle}
                                attributes={attributes}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )) || <span>В данной категории нет магазинов</span>}
                  </div>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default Home;
