import React, { useEffect } from "react";

import { useNavigate, Link, useLocation } from "react-router-dom";
import history from "../../history";
import { useDispatch } from "react-redux";

import "./style.css";
import { setClearShop } from "../../modules/shop/actions";
import { setClearCategory } from "../../modules/category/actions";

const Inner = (props, test) => {
  const { header, content, dopClass, footer } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleBack = (e) => {
    e.preventDefault();

    if (dopClass == "_shop") {
      dispatch(setClearShop());
    }
    if (dopClass == "_category") {
      dispatch(setClearCategory());
    }
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className={`page _inner ${dopClass}`}>
      <div className="page__bg"></div>
      <div className="page__wrap">
        <div className="page__header">
          <Link
            to="/"
            relative="path"
            className="page__header-back"
            onClick={handleBack}
          >
            {(window.innerWidth < 1023 && dopClass == "_shop" && (
              <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-back-white.svg" />
            )) || (
              <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-back.svg" />
            )}
          </Link>
          {header}
        </div>
        <div className="page__content">{content}</div>
        {footer && <div className="page__footer">{footer}</div>}
      </div>
    </div>
  );
};

export default Inner;
