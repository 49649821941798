import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Modal from "../../layout/modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "./style.css";
import { getSale, getSaleIdle, setClearSale } from "../../modules/sale/actions";
import { targetMetrick } from "../../constants/config";
import { Link } from "react-router-dom";
import history from "../../history";

const Content = (props) => {
  const { copyPromocode, setCopyPromocode, showSale, setShowSale } = props;

  const data = useSelector((state) => state.sale.data);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (copyPromocode) {
      setTimeout(function () {
        setCopyPromocode(false);
      }, 5000);
    }
  }, [copyPromocode]);

  const handleClosePromocode = (e) => {
    navigate(location.pathname, { replace: true });
    setShowSale(false);
    dispatch(getSaleIdle());
  };

  const handleCopyPromocode = (e) => {
    e.preventDefault();

    if (!copyPromocode) {
      setCopyPromocode(true);
      navigator.clipboard.writeText(data.attributes.Sale_card.Promocode);

      targetMetrick(
        "Копировать промокод. Акция. " + data.attributes.Sale_card.Title_inner
      );
    }
  };

  return (
    <div className="promocode">
      {(window.innerWidth < 1023 && (
        // <Link
        //   to={window.location.pathname}
        //   className="shop__promocode-close"
        // >
        //   <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-close-small.svg" />
        // </Link>
        <button
          className="shop__promocode-close"
          onClick={() => {
            navigate(location.pathname, { replace: true });
            setShowSale(false);
            dispatch(getSaleIdle());
          }}
        >
          <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-close-small.svg" />
        </button>
      )) || (
        <button
          className="shop__promocode-close"
          onClick={handleClosePromocode}
        >
          <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-close-small.svg" />
        </button>
      )}
      {data && (
        <>
          {data.attributes.Sale_card.Title_inner && (
            <div
              className="promocode__title"
              dangerouslySetInnerHTML={{
                __html: data.attributes.Sale_card.Title_inner,
              }}
            ></div>
          )}
          {/* || (
                        <>Скидка {data.attributes.Sale_card.Percent}% по промокоду от магазина {data.attributes.Sale_card.Title}</>
                    )} */}
          {data.attributes.Sale_card.Info && (
            <div className="promocode__lead">
              <ReactMarkdown linkTarget="_blank">
                {data.attributes.Sale_card.Info}
              </ReactMarkdown>
            </div>
          )}
          {data.attributes.Sale_card.Promocode && (
            <div className="promocode__value">
              {data.attributes.Sale_card.Promocode}
              <a
                href="#"
                onClick={handleCopyPromocode}
                className="promocode__value-copy"
              >
                <img src="https://cdn.podeli.ru/web-catalog/assets/img/icon-copy.svg" />
              </a>
            </div>
          )}
          <div className="promocode__info">
            <ReactMarkdown linkTarget="_blank">
              {data.attributes.Sale_card.Organizator}
            </ReactMarkdown>
          </div>
          <div className="promocode__footer">
            {data.attributes.Sale_card.Web_store && (
              <a
                href={
                  data.attributes.Sale_card.Web_store +
                  ( !data.attributes.Sale_card.Hide_web_store_utm && "?utm_source=podeli.ru&utm_medium=banner&utm_campaign=podeli_promo" || '' )
                }
                onClick={(e) => {
                  targetMetrick(
                    data.attributes.Sale_card.Title_inner + ".Интернет-магазин"
                  );
                }}
                target="_blank"
                className="button _default"
              >
                {data.attributes.Sale_card.Web_store_title && data.attributes.Sale_card.Web_store_title || 'Интернет-магазин'}
              </a>
            )}
            {data.attributes.Sale_card.Retail_store && (
              <a
                href={data.attributes.Sale_card.Retail_store}
                onClick={(e) => {
                  targetMetrick(
                    data.attributes.Sale_card.Title_inner +
                      ".Розничные магазины"
                  );
                }}
                target="_blank"
                className={`button _default ${
                  (data.attributes.Sale_card.Web_store && "_gray") || ""
                }`}
              >
                {data.attributes.Sale_card.Retail_store_title && data.attributes.Sale_card.Retail_store_title || 'Розничные магазины'}
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Sale = (props) => {
  const dispatch = useDispatch();
  const [showSale, setShowSale] = useState(false);

  const [copyPromocode, setCopyPromocode] = useState(false);

  const saleId = useSelector((state) => state.sale.id);
  const status = useSelector((state) => state.sale.status);

  useEffect(() => {
    if (status == "SUCCESS") {
      dispatch(getSaleIdle());
    }
  }, [status]);

  let location = useLocation();
  const { results } = useParams();

  /*useEffect(() => {
    const params = new URLSearchParams(location.search);
    const saleUrlId = params.get("sale");
    if (!saleUrlId) {
      setShowSale(false);
    } else {
      setShowSale(true);
    }
  }, [location]);*/

  useEffect(() => {
    if (saleId) {
      setShowSale(true);
      if (status == "IDLE") {
        dispatch(getSale(saleId));
      }
    } else {
      setShowSale(false);
    }
  }, [saleId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const saleUrlId = params.get("sale");
    const saleId = {
      id: Number(saleUrlId),
    };
    if (saleUrlId) {
      setShowSale(true);
      dispatch(getSale(saleId));
    } else {
      setShowSale(false);
    }
  }, [history, location.search, location.hash]);

  return (
    <Modal
      show={showSale}
      toggle={setShowSale}
      header={
        <div
          className={`shop__promocode-alert ${
            (copyPromocode && "_show") || ""
          }`}
        >
          <span>Промокод скопирован</span>
        </div>
      }
      content={
        <Content
          copyPromocode={copyPromocode}
          setCopyPromocode={setCopyPromocode}
          showSale={showSale}
          setShowSale={setShowSale}
        />
      }
      dopClass={"_sale"}
    ></Modal>
  );
};

export default Sale;
