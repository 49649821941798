import {createActions} from "redux-actions";

const {
    getShopsByFilter,
    getShopsByFilterSuccess,
    getShopsByFilterFailure,
    getShopsByFilterIdle,
} = createActions(
    "GET_SHOPS_BY_FILTER",
    "GET_SHOPS_BY_FILTER_SUCCESS",
    "GET_SHOPS_BY_FILTER_FAILURE",
    "GET_SHOPS_BY_FILTER_IDLE",
);


export {
    getShopsByFilter,
    getShopsByFilterSuccess,
    getShopsByFilterFailure,
    getShopsByFilterIdle,
};
