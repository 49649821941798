import "regenerator-runtime/runtime";
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import sagas from "./rootSaga";
import rootReducer from "./rootReducer";
import {configureStore} from "@reduxjs/toolkit";

const createAppStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  let store;
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    store = configureStore({
      reducer: rootReducer,
      middleware: [sagaMiddleware],
      devTools: window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    })
  } else {
    store = configureStore({
      reducer: rootReducer,
      middleware: [sagaMiddleware]
    });
  }

  sagaMiddleware.run(sagas);

  return store;
};

export default createAppStore;
