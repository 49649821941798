import React, { useState, useEffect } from "react";
import ItemCity from "../itemCity/itemCity";

import { useSelector } from 'react-redux';

import './style.css'

const ListCities = (props) => {

    const [list, setList] = useState([]);

    const currentCity = useSelector(state => state.initData.city);

    const cities = useSelector(state => state.initData.cities);

    const { valueSearch } = props;

    useEffect(() => {
        if(valueSearch && valueSearch.length > 0){
            let filterList = cities.filter(item => item.title.toLowerCase().indexOf(valueSearch.toLowerCase()) >= 0);
            setList(filterList);
        } else {
            setList(cities);
        }
    }, [valueSearch])

    useEffect(() => {
        if(cities){
            setList(cities);
        }
    }, [cities])

    return (
        <div className="cities">
            {list && list.map((item, index) => {
                return (
                    <div key={item.id} className="cities__item">
                        <ItemCity
                            {...item}
                            checked={currentCity.id == item.id && true || false}
                        />
                    </div>     
                )
            })}
        </div>
    )    
}

export default ListCities;