import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getShop,
    getShopSuccess,
    getShopFailure,
    getShopIdle,
    setClearShop
} from "./actions";

const status = handleActions(
    {
        [getShopSuccess]: () => "SUCCESS",
        [getShop]: () => "LOADING",
        [getShopFailure]: () => "FAILURE",
        [getShopIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getShopSuccess]: (state, action) => action.payload.shop.data,
        [setClearShop]: (state, action) => null
    },
    null
)

const isOnlineShop = handleActions(
    {
        [getShopSuccess]: (state, action) => {
            let status = false;
            if(action.payload.shop.data.attributes.Store_types && action.payload.shop.data.attributes.Store_types.data && action.payload.shop.data.attributes.Store_types.data.findIndex(item => item.id == 1) >= 0){
                status = true;
            }
            return status;
        },
    },
    null
)

const isOfflineShop = handleActions(
    {
        [getShopSuccess]: (state, action) => {
            let status = false;
            if(action.payload.shop.data.attributes.Store_types && action.payload.shop.data.attributes.Store_types.data && action.payload.shop.data.attributes.Store_types.data.findIndex(item => item.id == 2) >= 0){
                status = true;
            }
            return status;
        }
    },
    null
)

const relatedShops = handleActions(
    {
        [getShopSuccess]: (state, action) => action.payload.shopsCategoryData,
        [setClearShop]: (state, action) => null
    },
    null
)

const shop = combineReducers({
    status,
    data,
    isOnlineShop,
    isOfflineShop,
    relatedShops
});

export {shop};
