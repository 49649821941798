import {createActions} from "redux-actions";

const {
    getSales,
    getSalesSuccess,
    getSalesFailure,
    getSalesIdle,
} = createActions(
    "GET_SALES",
    "GET_SALES_SUCCESS",
    "GET_SALES_FAILURE",
    "GET_SALES_IDLE",
);


export {
    getSales,
    getSalesSuccess,
    getSalesFailure,
    getSalesIdle,
};
