import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";

import { getReguest } from "../initData/api";

import {
  getShop,
  getShopSuccess,
  getShopFailure,
  getShopIdle,
} from "./actions";
import { compose } from "redux";
import { selectConfig } from "../config/selector";

function* getShopSaga(action) {
  try {
    let { payload } = action;
    const config = yield select(selectConfig);
    const { id } = payload;

    let populate =
      "populate[Store_types][populate]=*" +
      "&populate[Categories][populate]=Shops" +
      "&populate[Logo][populate]=*" +
      "&populate[Preview][populate]=*" +
      "&populate[Sale][populate]=*" +
      "&populate[Sale][Sale_card][populate]=*" +
      "&populate[Sale_card][populate]=*" +
      "&populate[Sale_alpha][populate]=*" +
      "&populate[Alpha_card][populate]=*";
    // "&populate[Sale_alpha][populate]=Alpha_card.Preview" +
    // "&populate[Sale_alpha][populate]=Alpha_card.Title_on_gray_group" +
    // "&populate[Sale_alpha][populate]=Alpha_card.SubTitle_on_gray_group";

    let routeShop = "/api/shops/" + id + "/?" + populate;

    var regex = /^\d+$/;
    // Используем метод match для поиска совпадений
    var match = id.match(regex);

    if (!match) {
      routeShop = "/api/shops?filters[Slug][$eq]=" + id + "&" + populate;
    }

    const [shop] = yield all([
      call(getReguest, {
        route: routeShop,
        ...config,
      }),
    ]);

    let shopsCategoryData = null;

    let routeDefault =
      "/api/shops?fields[0]=Title&fields[1]=Subtitle&fields[2]=Show_percent_on_preview&fields[3]=Slug&populate=Preview,Preview_list,Logo,Sort&populate=Sale.Sale_card&populate=Sale_card&";
    let related = [];
    if (
      (shop &&
        shop.data &&
        shop.data.attributes &&
        shop.data.attributes.Categories &&
        shop.data.attributes.Categories.data) ||
      (shop &&
        shop.data &&
        shop.data.length > 0 &&
        shop.data[0].attributes &&
        shop.data[0].attributes.Categories &&
        shop.data[0].attributes.Categories.data)
    ) {
      let shopData = shop.data;
      if (Array.isArray(shopData)) {
        shopData = shop.data[0];
      }
      shopData.attributes.Categories.data.map((category, index) => {
        if (
          category.attributes.Shops &&
          category.attributes.Shops.data &&
          category.attributes.Shops.data.length > 0
        ) {
          category.attributes.Shops.data.map((shop) => {
            related = [...related, shop.id];
          });
        }
      });

      if (related.length > 0) {
        related = related.filter((item) => item !== id);
        if (related.length > 8) {
          related = related.slice(0, 8);
        }

        // if(index == 0){
        related.map((id, index) => {
          routeDefault =
            routeDefault + "filters[id][$in][" + index + "]=" + id + "&";
        });

        routeDefault = routeDefault + "pagination[pageSize]=8";
        const [shopCategory] = yield all([
          call(getReguest, {
            route: routeDefault,
            ...config,
          }),
        ]);
        let shopCategoryObj = {};
        if (shopCategory.data && shopCategory.data.length > 0) {
          shopCategory.data.map((shop) => {
            shopCategoryObj[shop.id] = shop;
          });
        }
        shopsCategoryData = shopCategoryObj;
      }

      let shopOject = shop;
      if (Array.isArray(shopOject.data)) {
        shopOject = shop.data[0];
      } else {
        shopOject = shop.data;
      }

      yield put(
        getShopSuccess({
          shop: {
            data: shopOject,
            meta: shop.meta,
          },
          shopsCategoryData,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(getShopFailure(error));
  }
}

export function* getShopWatcher() {
  yield takeLatest(getShop.toString(), getShopSaga);
}
