import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getSale,
    getSaleSuccess,
    getSaleFailure,
    getSaleIdle,
    setClearSale,
    setIdSale
} from "./actions";

const status = handleActions(
    {
        [getSaleSuccess]: () => "SUCCESS",
        [getSale]: () => "LOADING",
        [getSaleFailure]: () => "FAILURE",
        [getSaleIdle]: () => "IDLE",
    },
    'IDLE'
);

const id = handleActions(
    {
        [setIdSale]: (state, action) => action.payload,
        [setClearSale]: (state, action) => null
    },
    null
)

const data = handleActions(
    {
        [getSaleSuccess]: (state, action) => action.payload.data,
        [setClearSale]: (state, action) => null
    },
    null
)

const sale = combineReducers({
    status,
    data,
    id
});

export {sale};
