import {createActions} from "redux-actions";

const {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
    getSearchClear,
    setSearchValue
} = createActions(
    "GET_SEARCH",
    "GET_SEARCH_SUCCESS",
    "GET_SEARCH_FAILURE",
    "GET_SEARCH_IDLE",
    "GET_SEARCH_CLEAR",
    "SET_SEARCH_VALUE"
);


export {
    getSearch,
    getSearchSuccess,
    getSearchFailure,
    getSearchIdle,
    getSearchClear,
    setSearchValue
};
