import React from "react";

import "./style.css";

import PostShop from "../../../../components/postShop";
import { getUrlImage } from "../../../../constants/config";

import { useSelector } from "react-redux";
import { selectCategoriesById } from "../../../../modules/initData/selector";

const Results = (props) => {
  const { shops } = props;

  const status = useSelector((state) => state.search.status);
  const message = useSelector((state) => state.search.message);
  const categoriesById = useSelector(selectCategoriesById);

  return (
    <div className="results _search">
      <div className="results__category">
        <div className="category _default _products">
          <div className="category__list _vertical">
            {(shops &&
              shops.length > 0 &&
              shops.map((item, index) => {
                const {
                  id,
                  title,
                  previewUrl,
                  logoUrl,
                  categoryTitle,
                  subTitle,
                  sale,
                  attributes,
                } = item;

                return (
                  <div className="category__list-item" key={id}>
                    <PostShop
                      link={`/shop/${id}`}
                      preview={previewUrl}
                      logo={logoUrl}
                      title={title}
                      category={categoryTitle}
                      dopClass="_search"
                      sale={sale}
                      subTitle={subTitle}
                      attributes={attributes}
                    />
                  </div>
                );
              })) ||
              (message !== "" && message == "not-found" && (
                <>
                  <div className="not-found">
                    <div className="not-found__title">Ничего не найдено</div>
                    <div className="not-found__description">
                      Попробуйте изменить поисковой запрос или посмотрите наши
                      рекомендации
                    </div>
                  </div>
                  <div className="results _category">
                    {[14, 13].map((idCategory) => {
                      return (
                        <div className="category _default">
                          <div className="category__header">
                            <div className="category__title">
                              {categoriesById &&
                                categoriesById[idCategory] &&
                                categoriesById[idCategory].Title}
                            </div>
                          </div>
                          <div className="category__list _vertical">
                            {categoriesById[idCategory].Shops.data.map(
                              (item, index) => {
                                const { id, attributes } = item;

                                const {
                                  Title,
                                  Preview,
                                  Logo,
                                  Sale,
                                  Sale_card,
                                  Preview_list,
                                  Subtitle,
                                  Show_percent_on_preview,
                                } = attributes;

                                let Percent = null;

                                let Percent_symbol = null;

                                if (Sale_card) {
                                  if (Sale_card.Percent) {
                                    Percent = Sale_card.Percent;
                                  }
                                  if (Sale_card.Percent_symbol) {
                                    Percent_symbol = Sale_card.Percent_symbol;
                                  }
                                } else {
                                  if (
                                    Sale &&
                                    Sale.data &&
                                    Sale.data.attributes &&
                                    Sale.data.attributes.Sale_card
                                  ) {
                                    if (
                                      Sale.data.attributes.Sale_card.Percent
                                    ) {
                                      Percent =
                                        Sale.data.attributes.Sale_card.Percent;
                                    }
                                    if (
                                      Sale.data.attributes.Sale_card
                                        .Percent_symbol
                                    ) {
                                      Percent_symbol =
                                        Sale.data.attributes.Sale_card
                                          .Percent_symbol;
                                    }
                                  }
                                }

                                return (
                                  <div key={id} className="category__list-item">
                                    <PostShop
                                      link={"/shop/" + id}
                                      preview={
                                        (Preview_list &&
                                          getUrlImage(Preview_list)) ||
                                        (Preview && getUrlImage(Preview)) ||
                                        ""
                                      }
                                      logo={(Logo && getUrlImage(Logo)) || ""}
                                      title={Title}
                                      sale={
                                        ((Show_percent_on_preview ||
                                          Show_percent_on_preview == null) &&
                                          Percent &&
                                          "-" +
                                            Percent +
                                            ((Percent_symbol &&
                                              Percent_symbol) ||
                                              "%")) ||
                                        null
                                      }
                                      category={
                                        categoriesById &&
                                        categoriesById[id] &&
                                        categoriesById[id].Title
                                      }
                                      subTitle={Subtitle}
                                      attributes={attributes}
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )) || <span>{message}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
