import { createActions } from "redux-actions";

const { getConfig, getConfigSuccess, getConfigFailure, getConfigIdle } =
  createActions(
    "GET_CONFIG",
    "GET_CONFIG_SUCCESS",
    "GET_CONFIG_FAILURE",
    "GET_CONFIG_IDLE"
  );

export { getConfig, getConfigSuccess, getConfigFailure, getConfigIdle };
