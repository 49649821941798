import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";

import { getReguest } from "../initData/api";

import {
  getCategory,
  getCategorySuccess,
  getCategoryFailure,
  getCategoryIdle,
} from "./actions";
import { selectConfig } from "../config/selector";

function* getCategorySaga(action) {
  try {
    let { payload } = action;
    const config = yield select(selectConfig);
    const { id, currentCity, topShops } = payload;

    let idsTopShops = [];
    let idsNotTopShops = [];

    if (topShops && topShops.data && topShops.data.length > 0) {
      topShops.data.map((item, index) => {
        idsTopShops.push("filters[id][$in][" + index + "]=" + item.id);
      });
    }

    let populate = "fields[0]=Title";
    populate = populate + "&fields[1]=Subtitle";
    populate = populate + "&fields[2]=Show_percent_on_preview";
    populate = populate + "&fields[3]=Web_store";
    populate = populate + "&fields[4]=IsExternal";
    populate = populate + "&populate=Preview,Preview_list,Logo,Sort";
    populate = populate + "&populate=Sale.Sale_card";
    populate = populate + "&populate=Sale_card";
    populate = populate + "&populate=Shops";

    let routeShops =
      "/api/shops/?filters[Categories][id][$in]=" +
      id +
      "&" +
      populate +
      "&pagination[pageSize]=1000";
    let routeSales =
      "/api/sales/?sort=Sort&filters[Categories][id][$in]=" +
      id +
      "&populate[Sale_card][populate]=*";

    if (currentCity && currentCity.id !== "all") {
      routeShops = routeShops + "&filters[Cities][id][$in]=" + currentCity.id;
    }

    let shopsAll = [];

    const [
      category,
      shops,
      sales,
      // topShopsResult
    ] = yield all([
      call(getReguest, {
        route:
          "/api/categories/" +
          id +
          "?populate[Shops][populate]=Preview,Preview_list,Logo,Sale_card",
        ...config,
      }),
      call(getReguest, { route: routeShops, ...config }),
      call(getReguest, { route: routeSales, ...config }),
    ]);

    if (
      category &&
      category.data.attributes.Shops &&
      category.data.attributes.Shops.data &&
      category.data.attributes.Shops.data.length > 0
    ) {
      shopsAll = [
        // ...shopsAll,
        ...category.data.attributes.Shops.data,
      ];
    }

    yield put(
      getCategorySuccess({
        shops: shopsAll,
        sales,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(getCategoryFailure(error));
  }
}

export function* getCategoryWatcher() {
  yield takeLatest(getCategory.toString(), getCategorySaga);
}
