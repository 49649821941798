import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getShopsByFilter,
    getShopsByFilterSuccess,
    getShopsByFilterFailure,
    getShopsByFilterIdle,
} from "./actions";

const status = handleActions(
    {
        [getShopsByFilterSuccess]: () => "SUCCESS",
        [getShopsByFilter]: () => "LOADING",
        [getShopsByFilterFailure]: () => "FAILURE",
        [getShopsByFilterIdle]: () => "IDLE",
    },
    'IDLE'
);

const data = handleActions(
    {
        [getShopsByFilterSuccess]: (state, action) => action.payload,
    },
    null
)

const shopsByFilter = combineReducers({
    status,
    data
});

export { shopsByFilter };
