import React, { useEffect, useContext } from "react";

import "./style.css";

import { useSelector, useDispatch } from "react-redux";

import Modal from "../modal/modal";
import SearchForm from "../../components/searchForm";
import Results from "./components/results";
import { setSearch } from "../../modules/initData/actions";
import {
  getSearch,
  getSearchClear,
  getSearchIdle,
} from "../../modules/search/actions";
import { selectorSearchShops } from "../../modules/search/selector";
import { targetMetrick } from "../../constants/config";
import cn from "classnames";
import { Theme } from '../../context';
import { isCatalog } from "../../constants/config";
let timeoutSearch = null;

const Search = (props) => {
  const dispatch = useDispatch();

  const theme = useContext(Theme);

  const {
    showSearch,
    setShowSearch,
    setInputSearchRef,
    valueSearch,
    setValueSearch,
    setValueSearchHome,
    valueSearchHome,
    globalSearchValue, 
    setGlobalSearchValue
  } = props;

  // const valueSearch = useSelector(state => state.initData.search);

  const shops = useSelector(selectorSearchShops);
  const status = useSelector((state) => state.search.status);
  const stateSearchValue = useSelector(state => state.search.searchValue);

  useEffect(() => {
    if (status == "SUCCESS") {
      dispatch(getSearchIdle());
    }
  }, [status]);

  const handleSearch = (value) => {
    // if(valueSearchHome !== ''){
    //     setValueSearchHome('');
    // }
    // setValueSearch(value);
    // if(valueSearch !== value){
    // clearTimeout(timeoutSearch);
    // timeoutSearch = setTimeout(function(){
    //     if(status == 'IDLE' && value.length > 2){
    //         dispatch(getSearch({ searchValue: value.toLowerCase() }))
    //         // dispatch(setSearch(value));
    //         // dispatch(getSearch({ searchValue: value.toLowerCase() }));
    //     }
    // }, 1000);
    // }
  };

  useEffect(() => {
    if (!showSearch) {
      theme.setGlobalSearchValue("");
    }
  }, [showSearch]);

  useEffect(() => {
    if(theme.globalSearchValue && theme.globalSearchValue.length){
      clearTimeout(timeoutSearch);

      timeoutSearch = setTimeout(function () {
        if (status == "IDLE" && theme.globalSearchValue.length > 0) {
          targetMetrick("Поиск на сайте");

          dispatch(getSearch({ searchValue: theme.globalSearchValue.toLowerCase() }));
        }
      }, 1000);
    }
  }, [theme.globalSearchValue])

  return (
    <Modal
      show={showSearch}
      toggle={setShowSearch}
      header={
        <SearchForm
          setInputOutRef={setInputSearchRef}
          // globalSearchValue={globalSearchValue}
          // setGlobalSearchValue={setGlobalSearchValue}
          // valueSearch={valueSearch}
          // callback={(value) => {
          //   if (value) {
          //     setValueSearch(value);

          //     clearTimeout(timeoutSearch);

          //     timeoutSearch = setTimeout(function () {
          //       if (status == "IDLE" && value.length > 0) {
          //         targetMetrick("Поиск на сайте");

          //         dispatch(getSearch({ searchValue: value.toLowerCase() }));
          //       }
          //     }, 1000);
          //   }
          // }}
        />
      }
      content={<Results shops={shops} />}
      dopClass={cn("_search", isCatalog && "_catalog")}
    ></Modal>
  );
};

export default Search;
