import React, { useState } from "react";
import Modal from "../modal/modal";
import ListFilter from "./components/listFilter";

import { useDispatch, useSelector } from 'react-redux';

import { clearFilter } from '../../modules/filter/actions';

const Filter = (props) => {

    const dispatch = useDispatch();

    const { showFilter, setShowFilter, type } = props;

    const activesTypes = useSelector(state => state.filter.activesTypes);
    const activesCategory = useSelector(state => state.filter.activesCategory);

    const handleReset = (e) => {
        e.preventDefault();

        if(document.querySelector('input[name="category"][value="all"]:checked')){
            document.querySelector('input[name="category"][value="all"]').checked = false;
        }

        dispatch(clearFilter([]));

    }

    const handleAcceptFilter = () => {
        setShowFilter(!showFilter);
    }

    if(type == 'inline'){
        return (
            <ListFilter />
        )
    } else {
        return (
            <Modal 
                show={showFilter} 
                toggle={setShowFilter} 
                header={( activesTypes.length > 0 || activesCategory.length > 0 ) && <a href="#" className="filter-reset" onClick={handleReset}>Очистить</a>} 
                content={<ListFilter />}
                footer={<button onClick={handleAcceptFilter} type="button" className="button _default">Применить фильтры</button>}
                dopClass={'_filter'}
            ></Modal>
        )
    }
}

export default Filter;