import {
  call,
  put,
  takeEvery,
  all,
  takeLatest,
  select,
} from "redux-saga/effects";

import { getReguest } from "../initData/api";

import {
  getSale,
  getSaleSuccess,
  getSaleFailure,
  getSaleIdle,
} from "./actions";
import { selectConfig } from "../config/selector";

function* getSaleSaga(action) {
  try {
    let { payload } = action;
    const config = yield select(selectConfig);
    const { id } = payload;

    const [sale] = yield all([
      call(getReguest, {
        route: "/api/sales/" + id + "/?populate[Sale_card][populate]=*",
        ...config,
      }),
    ]);

    yield put(getSaleSuccess(sale));
  } catch (error) {
    console.log(error);
    yield put(getSaleFailure(error));
  }
}

export function* getSaleWatcher() {
  yield takeLatest(getSale.toString(), getSaleSaga);
}
