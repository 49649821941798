import {createActions} from "redux-actions";

const {
    getSale,
    getSaleSuccess,
    getSaleFailure,
    getSaleIdle,
    setClearSale,
    setIdSale
} = createActions(
    "GET_SALE",
    "GET_SALE_SUCCESS",
    "GET_SALE_FAILURE",
    "GET_SALE_IDLE",
    "SET_CLEAR_SALE",
    'SET_ID_SALE'
);


export {
    getSale,
    getSaleSuccess,
    getSaleFailure,
    getSaleIdle,
    setClearSale,
    setIdSale
};
