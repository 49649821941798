import React, { useEffect, useState } from "react";

import Inner from "../../layout/inner";
import ListSales from "./components/listSales";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import './style.css'
import { getSales, getSalesIdle } from "../../modules/sales/actions";

const Sales = (props) => {

    const params = useParams();

    const dispatch = useDispatch();

    const status = useSelector(state => state.sales.status);
    const data = useSelector(state => state.sales.data);

    useEffect(() => {
        if(dispatch && status == 'IDLE'){
            dispatch(getSales({
                id: params.id
            }));
        }
    }, [dispatch])

    useEffect(() => {
        if(status == 'SUCCESS' || status == 'FAILURE'){
            dispatch(getSalesIdle());
        }
    }, [status])

    return (
        <Inner
            content={<ListSales data={data} />}
            dopClass={'_sales'}
        ></Inner>
    )
}

export default Sales;