import { combineReducers } from "redux";
import { initData } from "./modules/initData/reducer";
import { filter } from "./modules/filter/reducer";
import { search } from "./modules/search/reducer";
import { sale } from "./modules/sale/reducer";
import { sales } from "./modules/sales/reducer";
import { shopsByFilter } from "./modules/shopsByFilter/reducer";
import { shop } from "./modules/shop/reducer";
import { category } from "./modules/category/reducer";
import { config } from "./modules/config/reducer";

export default combineReducers({
  initData,
  filter,
  search,
  sale,
  sales,
  shopsByFilter,
  shop,
  category,
  config,
});
