import React, { useEffect, useRef } from "react";

const FilterCheckbox = (props) => {

    const inputRef = useRef(null);

    const { title, value, name, checked, callback } = props;

    useEffect(() => {
        
        if(checked){
            inputRef.current.checked = checked;
        } else {
            inputRef.current.checked = false;
        }

    }, [checked])

    const handleChange = (e) => {
        callback(e);
    }

    return (
        <label className="checkbox _filter">
            <input ref={inputRef} className="checkbox__input" onChange={handleChange} type="checkbox" value={value} name={name} />
            <span className="checkbox__wrap">
                <span className="checkbox__title">{title}</span>
                {window.innerWidth < 1023 && (
                    <span className="checkbox__switche">
                        <span className="switche">
                            <span className="switche__bull"></span>
                        </span>
                    </span>
                ) || (
                    <span className="checkbox__check">
                        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 4.00001L3.58 6.83001L9.25 1.17001" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                )}
            </span>
        </label>
    )
}

export default FilterCheckbox;