import { createSelector } from 'reselect';

const stateShopsByFilter = state => state.shopsByFilter.data;

export const selectorShopsByFilter = createSelector(stateShopsByFilter, data => {

    let shops = data;


    return shops;
});