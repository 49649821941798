import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    setFilterTypes,
    setFilterCategory,
    clearFilter
} from "./actions";

const activesTypes = handleActions(
    {
        [setFilterTypes]: (state, action) => action.payload,
        [clearFilter]: (state, action) => []
    },
    []
)

const activesCategory = handleActions(
    {
        [setFilterCategory]: (state, action) => action.payload,
        [clearFilter]: (state, action) => []
    },
    []
)

const filter = combineReducers({
    activesTypes,
    activesCategory
});

export {filter};
